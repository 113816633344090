import React, { useState } from 'react'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { Modal } from '@faststore/ui'
import { Image } from 'src/components/ui/Image'

import type { ProductDataProps } from './typings/productData'
import ProductCustomBuyButton from './ProductCustomBuyButton'

import './styles.scss'

interface Props extends HandleProductCustomModalProps, ProductDataProps {
  BuyButtonComponent: React.ElementType<
    React.ButtonHTMLAttributes<HTMLButtonElement>
  >
}

const FONT_SIZE_FALLBACK = 10

function verifyCharacters(value: string) {
  const regex = new RegExp(/[^a-zA-Z0-9]/)

  for (let i = 0; i < value?.length; i++) {
    if (regex.test(value[i])) {
      return false
    }
  }

  return true
}

const HandleProductCustomModal = (props: Props) => {
  const {
    modalCustomStyle,
    modalVisible,
    modalCustomContainerStyle,
    handleCloseModal,
    modalTitleStyle,
    buttonCustomText,
    modalDescriptionStyle,
    attachmentConfig,
    modalImageStyle,
    productData,
    modalCustomTextStyle,
    customText,
    modalFormText,
    modalFormInputStyle,
    attachment,
    modalButtonClearStyle,
    clearCustomization,
    modalButtonClearText,
    customization,
    BuyButtonComponent,
    buyDisabled,
    modalButtonAddText,
    handleChange,
    buyProps,
  } = props

  const originalFontSize = Number(modalCustomTextStyle?.fontSize)
  const [fontSizes, setFontSizes] = useState<number[]>(
    customText.map(() => originalFontSize)
  )

  const { width } = useWindowDimensions()

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let newFontSize = originalFontSize
    const { value } = e.target
    const uppercaseCount = (value.match(/[A-Z]/g) || []).length
    const lowercaseCount = (value.match(/[a-z]/g) || []).length
    const isMobile = width <= 768

    const uppercasePercentage = isMobile
      ? (productData?.isVariantOf?.additionalProperty.find(
          (item) =>
            item.name ===
            'personalizacao_porcentagem_diminuicao_letra_maiuscula_mobile'
        )?.value as number) * 0.01
      : (productData?.isVariantOf?.additionalProperty.find(
          (item) =>
            item.name ===
            'personalizacao_porcentagem_diminuicao_letra_maiuscula_desktop'
        )?.value as number) * 0.01

    const lowercasePercentage = isMobile
      ? (productData?.isVariantOf?.additionalProperty.find(
          (item) =>
            item.name ===
            'personalizacao_porcentagem_diminuicao_letra_minuscula_mobile'
        )?.value as number) * 0.01
      : (productData?.isVariantOf?.additionalProperty.find(
          (item) =>
            item.name ===
            'personalizacao_porcentagem_diminuicao_letra_minuscula_desktop'
        )?.value as number) * 0.01

    handleChange(e)

    if (!uppercasePercentage || !lowercasePercentage) {
      return
    }

    const uppercaseAdjustment =
      uppercaseCount * uppercasePercentage * originalFontSize

    const lowercaseAdjustment =
      lowercaseCount * lowercasePercentage * originalFontSize

    newFontSize -= uppercaseAdjustment
    newFontSize -= lowercaseAdjustment

    if (newFontSize < FONT_SIZE_FALLBACK) {
      newFontSize = originalFontSize - 3
    }

    const updatedFontSizes = [...fontSizes]

    updatedFontSizes[index] = newFontSize
    setFontSizes(updatedFontSizes)
  }

  const visualText = customText.map((line: string, lineIndex: number) => {
    return (
      <span key={lineIndex} style={{ fontSize: `${fontSizes[lineIndex]}px` }}>
        {line}
        <br />
      </span>
    )
  })

  const customImage = productData?.image?.find(
    (image: { url: string; alternateName: string }) => {
      return image?.url?.indexOf('_custom.') !== -1
    }
  )

  return (
    <Modal
      style={modalCustomStyle}
      className="modalCustom"
      isOpen={modalVisible}
    >
      <div style={modalCustomContainerStyle} className="modalCustomContainer">
        <div className="closeContainer">
          <button className="closeButton" onClick={handleCloseModal}>
            &#x2715;
          </button>
        </div>
        <div className="modalTitleContainer">
          <h2 style={modalTitleStyle} className="modalTitle">
            {buttonCustomText}
          </h2>
          <p style={modalDescriptionStyle} className="modalDescription">
            {`Máximo de ${attachmentConfig?.maxRows} ${
              attachmentConfig?.maxRows > 1 ? 'linhas' : 'linha'
            } com ${attachmentConfig?.maxLength} ${
              attachmentConfig?.maxLength > 1 ? 'caracteres' : 'caractere'
            } , alguns caracteres especiais não são válidos`}
          </p>
          <p className="modalExchangeMessage">
            <strong>Atenção:</strong> Este item não pode ser trocado ou
            devolvido após a compra.
          </p>
        </div>
        <div className="modalImageContainer">
          <Image
            style={modalImageStyle}
            className="modalImage"
            baseUrl={customImage?.url ?? productData?.image?.[0]?.url}
            alt="Product for customization"
            aspectRatio={1}
            layout="constrained"
            loading="lazy"
            options={{
              fitIn: true,
            }}
          />
          <span className="modalCustomText" style={{ ...modalCustomTextStyle }}>
            {visualText}
          </span>
        </div>
        <div className="modalForm">
          <span className="modalFormText">{modalFormText}</span>
          {customText.map((text: string, index: number) => {
            return (
              <input
                key={index}
                data-key={index}
                style={modalFormInputStyle}
                maxLength={attachmentConfig?.maxLength}
                className={`modalFormInput ${
                  customization && customText ? 'modalFormInput__icon' : ''
                }`}
                placeholder={attachment?.Domains?.[index]?.FieldName}
                onChange={(e) => {
                  if (verifyCharacters(e?.target?.value) === false) {
                    return
                  }

                  handleInputChange(e, index)
                }}
                value={text}
              />
            )
          })}
          <button
            style={modalButtonClearStyle}
            className="modalButtonClear"
            onClick={() => {
              clearCustomization()
              setFontSizes(customText.map(() => originalFontSize))
            }}
          >
            {modalButtonClearText}
          </button>
          <ProductCustomBuyButton
            buyProps={buyProps}
            BuyButtonComponent={BuyButtonComponent}
            buyDisabled={buyDisabled}
            modalButtonAddText={modalButtonAddText}
          />
        </div>
      </div>
    </Modal>
  )
}

export default HandleProductCustomModal
