import { Script } from 'gatsby'
import React from 'react'

const MimoScript = () => {
  const scriptMimoPIP = () => `(function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "//pip.mimolivesales.com.br/v1/index.js";
        js.dataset.customerid = "3966933d-cb7e-4f66-ae92-a1ac3d33aac4";
        js.defer = true;
        fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'mimo-pip-sdk'));
`

  return (
    <>
      <Script
        key="mimoscript-pip"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: scriptMimoPIP(),
        }}
        async
      />
    </>
  )
}

export default MimoScript
