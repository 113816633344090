import React from 'react'

import GoogleTagManagerEco from './GoogleTagManagerEco'
import GoogleTagManager from './GoogleTagManager'
import Clarity from './Clarity'
import VTEX from './vtex'
import Ga from './Ga'
import OneTrust from './OneTrust'
import APMAzure from './APMAzure'
import InsiderScript from './InsiderScript'
import MimoScript from './MimoScript'

function ThirdPartyScripts() {
  return (
    <>
      <GoogleTagManagerEco />
      <GoogleTagManager />
      <Clarity />
      <VTEX />
      <Ga />
      <OneTrust />
      <APMAzure />
      <InsiderScript />
      <MimoScript />
    </>
  )
}

export default ThirdPartyScripts
